import React, { useState, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserContext from './contexts/UserContext';
import LoginPage from './components/LoginPage';
import AdminPage from './components/AdminPage';
import ThanksPage from './components/ThanksPage';
import EnrollPage from './components/EnrollPage';
import DrawPage from './components/DrawPage';
import HomePage from './components/HomePage';
import LogoutHandler from './components/LogoutHandler'; // Import the handleLogout function
import './App.css';

function App() {
    const { logout, login, user } = useContext(UserContext);
    const [curPage, setCurPage] = useState('login');
    const [isSignup, setIsSignup] = useState(false);
    const [isReEnroll, setIsReEnroll] = useState(false);
    const [selectedText, setSelectedText] = useState('');
    const [images, setImages] = useState([]);
    const handleLogout = LogoutHandler();

    // Check if the token has expired on page load
    useEffect(() => {
        if (user && user.exp) {
            const currentTime = Date.now() / 1000;
            const expirationTime = user.exp;
            if (currentTime > expirationTime) {
                console.log('Token expired, logging out');
                handleLogout();
            }
        }
    }, [user, handleLogout]);
    
    const setPage = (nextPage) => {
        setCurPage(nextPage)
    }

    const handleAuthComplete = async () => {    
        setPage('thanks');
    };

    const handleLoginDraw = async (userData) => {
        setImages(userData.images);
        setPage('login-draw');
    };

    const handleSignupDraw = async (userData) => {
        setIsSignup(true);
        setImages(userData.images);
        setPage('login-draw');
    };

    const handleReEnrollDraw = async () => {
        setIsReEnroll(true);
        setPage('login-draw');
    };

    const handleAdmin = () => {
        setPage('admin');
    }

    const handleHome = () => {
        setPage('home');
    }

    const handleTraining = () => {
        setPage('training')
    }

    const handleImageSelectTraining = async (text) => {
        setSelectedText(text);
        setPage('training-draw');
    };

    const handleDrawCompleteTraining = async (events, text) => {
        console.log(events)
        await logAction(events, 'log-events'); // Call logAction with the events array
        setPage('home');
    };

    // Function to log events to the server
    const logAction = async (events, endpoint='log-pending_events') => {
        const BATCH_SIZE = 20; // Adjust the batch size as needed
    
        const sendBatch = async (batch) => {
        try {
            const token = localStorage.getItem('token');
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(batch),
            });
    
            if (response.ok) {
            console.log('Batch logged successfully');
            } else {
            console.error('Failed to log batch');
            await handleLogout();
            }
        } catch (error) {
            console.error('Error:', error);
            await handleLogout();
        }
        };
    
        for (let i = 0; i < events.length; i += BATCH_SIZE) {
        const batch = events.slice(i, i + BATCH_SIZE);
        await sendBatch(batch);
        }
    };


    // Function to authenticate with TID
    const authenticate_TID = async () => {
        try {
            const logintoken = localStorage.getItem('token');
            let endpoint;
            if (isSignup) {
                endpoint = 'signup';
            } else if (isReEnroll) {
                endpoint = 're-enroll';
            } else {
                endpoint = 'login';
            }
            const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${endpoint}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${logintoken}`,
                },
            })

            if (response.ok) {
                const data = await response.json();
                console.log('Authenticated successfully');
                login(data.token);
            } else {
                console.error('Failed to authenticate');
                logout();
            }
        } catch (error) {
            console.error('Error:', error);
            logout();
        }
    };

    const _handleLoginDrawComplete = async (events, text) => {
        await logAction(events, 'log-pending_events'); // Call logAction with the events array
        await authenticate_TID();
        setPage('login');
    };

    const [handleDrawComplete, setHandleDrawComplete] = useState(() => _handleLoginDrawComplete);


    const handleImageSelectLogin = async (text) => {
        setSelectedText(text);
        setHandleDrawComplete(()=>_handleLoginDrawComplete);
        setPage('draw');
    };


    return (
        <div className="App">
        <Router>
            <Routes>
            {/* Main Application Route */}
            <Route path="/" element={
                <>
                {curPage === 'login' && <LoginPage 
                                            onAuthComplete={handleAuthComplete} 
                                            onImageSelectLogin={handleLoginDraw} 
                                            onImageSelectSignup={handleSignupDraw}
                                            onImageSelectReEnroll={handleReEnrollDraw}
                                        />}
                {curPage === 'admin' && <AdminPage />}
                {curPage === 'thanks' && <ThanksPage onHome={handleHome}/>}
                {curPage === 'home' && <HomePage onStart={handleTraining} onAdmin={handleAdmin}/>}
                {curPage === 'training' && <EnrollPage onImageSelect={handleImageSelectTraining} description={false} random={true}/>}
                {curPage === 'training-draw' && <DrawPage text={selectedText} onDrawComplete={handleDrawCompleteTraining} repetitions={3} />}
                {curPage === 'login-draw' && <EnrollPage onImageSelect={handleImageSelectLogin} description={isSignup || isReEnroll} includeImages={images}/>}
                {curPage === 'draw' && <DrawPage text={selectedText} onDrawComplete={handleDrawComplete} repetitions={(isSignup || isReEnroll)?3:1} />}
                </>
            } />
            </Routes>
        </Router>
        </div>
    );
}

export default App;
