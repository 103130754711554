// src/components/BigButton.js
import React from 'react';
import './BigButton.css';

const BigButton = ({ onClick, text = 'Start', comingSoon = false }) => {
  return (
    <div className="button-wrapper">
      <button 
        className={`big-button ${comingSoon ? 'coming-soon' : ''}`} 
        onClick={onClick}
        disabled={comingSoon}
      >
        {text}
        {comingSoon && (
          <span className="coming-soon-tape">COMING SOON</span>
        )}
      </button>
    </div>
  );
};

export default BigButton;