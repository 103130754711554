// src/components/EnrollPage.js

import React, { useState, useEffect, useMemo} from 'react';
import BigButton from './BigButton';
import LogoutButton from './LogoutButton';
import './EnrollPage.css';

// Dynamically require all image files in the specified folder
const importAllImages = (requireContext) => {
    let images = {};
    requireContext.keys().forEach((filename) => {
        const image = requireContext(filename);
        const imageName = filename.replace('./', '').split('.')[0].toUpperCase(); // Get the image name without extension
        images[imageName] = image.default || image;
    });
    return images;
};

const shuffleArray = (array) => {
    const shuffledArray = [...array]; // Copy the array to avoid mutation
    for (let i = shuffledArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [shuffledArray[i], shuffledArray[j]] = [shuffledArray[j], shuffledArray[i]]; // Swap elements
    }
    return shuffledArray;
};

// Import all images from the folder
const images = importAllImages(require.context('../assets/enroll/small', false, /\.(png|jpe?g|gif)$/));

const EnrollPage = ({ onImageSelect, description=true, random=false, includeImages=[]}) => {
    const [showStartSection, setShowStartSection] = useState(description);
    const [showEnrollSection, setShowEnrollSection] = useState(!description);
    const [selectedImage, setSelectedImage] = useState(null);
    const [chosenWord, setChosenWord] = useState('');

    // Memoize the image keys to avoid recalculating on each render
    const imageKeys = useMemo(() => {
        let keys = Object.keys(images);
        
        // Filter on includeImages if it has length
        if (includeImages.length > 0) {
            keys = keys.filter(key => includeImages.includes(key));
        }
    
        // Shuffle the keys randomly
        return shuffleArray(keys);
    }, []);

    useEffect(() => {
        if (random) {
            const randomIndex = Math.floor(Math.random() * imageKeys.length);
            const randomImageKey = imageKeys[randomIndex];
            const randomImage = images[randomImageKey];
            setSelectedImage({ src: randomImage, alt: randomImageKey });
            setChosenWord(randomImageKey);
            const timer = setTimeout(() => {
                onImageSelect(randomImageKey);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [random, imageKeys, onImageSelect]);


    const handleStartClick = () => {
        setShowStartSection(false);
        setShowEnrollSection(true);
    };

    const handleImageClick = (imageKey) => {
        const image = images[imageKey];
        setSelectedImage({ src: image, alt: imageKey });
        setChosenWord(imageKey);
        setTimeout(() => {
            onImageSelect(imageKey);
        }, 2000);
    };

    const handleClick = () => {
            if (chosenWord) {
                onImageSelect(chosenWord);
            }
        };

    return (
        <div className="enroll-page">
        <LogoutButton/>
        {!showEnrollSection && (
            <div className="enroll-page-intro">
            {showStartSection && (
                <div className="start-section show">
                    <p><strong>Choose Your Secret Object</strong></p>
                    To make your account extra secure, we ask you to choose an object that you can easily remember. This object will serve as a key to access your account.
                    <p><strong>Important Guidelines:</strong></p>
                    <ul>
                        <li>Keep it Secret: Only you should know this object.</li>
                        <li>Make it Memorable: Choose something you won't forget.</li>
                        <li>Do Not Share It: Treat it like a password – don't tell anyone or write it down where others can find it.</li>
                    </ul>
                    This secret object will be an extra layer of security to ensure only you can access your account.
                    <p><strong>Ready? Think carefully and choose your object!</strong></p>
                    <BigButton onClick={handleStartClick} text='NEXT'/>
                </div>
            )}
            </div>
        )}
        {showEnrollSection && (
            <>
            {(selectedImage || chosenWord) ? 
            ((selectedImage) ? (
                <div className="selected-image-container" onClick={handleClick}>
                <img src={selectedImage.src} alt={selectedImage.alt} className="selected-image" />
                <div className="image-text">
                    {selectedImage.alt.split('').map((char, index) => (
                    <span key={index} className="char-span">
                        {char}
                    </span>
                    ))}
                </div>
                </div>
            ): (
                <div className="selected-image-container" onClick={handleClick}>
                <div className="image-text">
                    {chosenWord.split('').map((char, index) => (
                    <span key={index} className="char-span">
                        {char}
                    </span>
                    ))}
                </div>
                </div>
            )) : (
                <>
                <div className="row">
                    {imageKeys.slice(0, 2).map((imageKey) => (
                    <img
                        key={imageKey}
                        src={images[imageKey]}
                        alt={imageKey}
                        className="enroll-image"
                        onClick={() => handleImageClick(imageKey)}
                    />
                    ))}
                </div>
                <div className="row">
                    {imageKeys.slice(2, 4).map((imageKey) => (
                    <img
                        key={imageKey}
                        src={images[imageKey]}
                        alt={imageKey}
                        className="enroll-image"
                        onClick={() => handleImageClick(imageKey)}
                    />
                    ))}
                </div>
                <div className="row">
                    {imageKeys.slice(4, 6).map((imageKey) => (
                    <img
                        key={imageKey}
                        src={images[imageKey]}
                        alt={imageKey}
                        className="enroll-image"
                        onClick={() => handleImageClick(imageKey)}
                    />
                    ))}
                </div>
                </>
            )}
            </>
        )}
        </div>
    );
};

export default EnrollPage;
