import React, { useState, useEffect, useContext } from 'react';
import './LoginPage.css';
import logo from '../assets/logo.png';
import BigButton from './BigButton';
import UserContext from '../contexts/UserContext';

const LoginPage = ({ onAuthComplete, onImageSelectLogin, onImageSelectSignup, onImageSelectReEnroll}) => {
  const [isAnimating, setIsAnimating] = useState(true);
  const [showLoginSection, setShowLoginSection] = useState(false);
  const [isSignup, setIsSignup] = useState(false);
  const [isSignin, setIsSignin] = useState(false);
  const [email, setEmail] = useState('');
  const { user, login } = useContext(UserContext);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsAnimating(false);
      const loginSectionTimer = setTimeout(() => {
        setShowLoginSection(true);
      }, 2000); // Additional delay to ensure logo animation is complete
      return () => clearTimeout(loginSectionTimer);
    }, 2000); // 2 seconds for logo animation

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (user && user.userId) {
        console.log('User logged in:', user);
        onAuthComplete();
    }
  }, [user, onAuthComplete]);

    const handleSignupClick = () => {
        if (isSignup) {
            const userAgent = navigator.userAgent;
            if (email && userAgent) {
                fetch(`${process.env.REACT_APP_API_BASE_URL}/token/signup`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, userAgent }),
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        alert(data.error);
                    } else {
                        login(data.token, onImageSelectSignup);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    alert('An error occurred. Please try again.');
                });
            } else {
                alert('Email or userAgent is missing');
            }
        } else {
            setIsSignup(true)
        }
    };

    const handleLoginClick = () => {
        if (isSignin) {
            const userAgent = navigator.userAgent;
            if (email && userAgent) {
                fetch(`${process.env.REACT_APP_API_BASE_URL}/token/login`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email, userAgent }),
                })
                .then((response) => response.json())
                .then((data) => {
                    if (data.error) {
                        alert(data.error);
                    } else if (data.isEnrolled){
                        login(data.token, onImageSelectLogin);
                    } else {
                        login(data.token, onImageSelectReEnroll);
                    }
                })
                .catch((error) => {
                    console.error('Error:', error);
                    alert('An error occurred. Please try again.');
                });
            } else {
                alert('Email or userAgent is missing');
            }
        } else {
            setIsSignin(true)
        }
    };

    const handleBack = () => {
        setIsSignup(false);
        setIsSignin(false);
    }

  return (
    <div className="login-page">
      <div className={`logo-section ${isAnimating ? '' : 'to-top'}`}>
        {(!isSignup && !isSignin) && (
            <img src={logo} alt="Tweekey Logo" />
        )
        }
      </div>
      {showLoginSection && (
        <div className="login-section show">
          
          {(isSignin || isSignup) && (
            <div>
                <p>Welcome to Tweekey, lets start to pick your favorite image and enroll your profile</p>
                <input
                type="email"
                placeholder="Email"
                className="login-input"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
            </div>
          )}
          <div className="button-container">
            {!isSignup && <BigButton onClick={handleLoginClick} text={isSignin ?  "START" : "Login"} comingSoon={false}/>}
            {!isSignin && <BigButton onClick={handleSignupClick} text={isSignup ?  "START" : "Register"}/>}
            {(isSignup || isSignin) && <BigButton onClick={handleBack} text="Back"/>}
          </div>
        </div>
      )}
    </div>
  );
};

export default LoginPage;
