// src/components/HomePage.js

import React, { useContext } from 'react';
import BigButton from './BigButton';
import LogoutButton from './LogoutButton';
import './HomePage.css';
import UserContext from '../contexts/UserContext';

const HomePage = ({ onStart, onAdmin}) => {
    const { user } = useContext(UserContext);

    return (
        <div className="home-page">
        <LogoutButton/>
        {(
            <div className="start-section-home show">
            <p>Welcome to your <strong>Tweekey</strong>!</p>
            <p>Feel like giving our AI training a little boost? Hit start and let’s draw some more letters together! We’d be super grateful for your help! </p>
            <BigButton onClick={onStart} text='START' />
            {user && user['role'] === 'admin' && (
                <div>
                    <br></br>
                    <BigButton onClick={onAdmin} text='Admin' />
                </div>
            )}
            </div>
        )}
        </div>
    );
};

export default HomePage;
