// src/components/LogoutButton.js

import React from 'react';
import LogoutHandler from './LogoutHandler'; // Import the handleLogout function
import './LogoutButton.css'; // Reuse the same CSS file
import logoutImage from '../assets/logout.png'; // Import the logout image

const LogoutButton = ({ onClick }) => {
  // Get the logout handler function
  const handleLogout = LogoutHandler();

  return (
    <button className="logout-button" onClick={onClick || handleLogout}>
      <img src={logoutImage} alt="Logout" />
    </button>
  );
};

export default LogoutButton;
