// src/components/LogoutHandler.js

import React, { useContext } from 'react';
import UserContext from '../contexts/UserContext';

// Function to log sign out data to the server
const logSignOut = async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/log-sign-out`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
        });

        if (response.ok) {
            console.log('Sign out logged successfully');
        } else {
            console.error('Failed to log sign out');
        }
    } catch (error) {
        console.error('Error:', error);
    }
};

const LogoutHandler = () => {
    const { user, logout } = useContext(UserContext);

    const handleLogout = async () => {
        if (user && user.userId) {
            console.log('User logged out:', user);
            await logSignOut();
        }
        await logout();
        window.location.reload(); // Force a page reload
    };

    return handleLogout;
};

export default LogoutHandler;