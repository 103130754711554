import React, { useState, useEffect, useRef, useContext } from 'react';
import UserContext from '../contexts/UserContext';
import LogoutButton from './LogoutButton';
import './DrawPage.css';
import { nanoid } from 'nanoid'

const DrawPage = ({ text , onDrawComplete, repetitions=3}) => {
    const [savedImages, setSavedImages] = useState([]);
    const [savedFullImages, setSavedFullImages] = useState([]);
    const { user } = useContext(UserContext);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentRep, setCurrentRep] = useState(0);
    const [wordId, setWordId] = useState('')
    const [charId, setCharId] = useState('')
    const [isDrawing, setIsDrawing] = useState(false);
    const [showNextButton, setShowNextButton] = useState(false);
    const [showCharacter, setShowCharacter] = useState(true);
    const [touchEvents, setTouchEvents] = useState([]);
    const [currentCharacterEvents, setCurrentCharacterEvents] = useState([]);
    const [hideButtons, setHideButtons] = useState(false);
    const [currentFont, setCurrentFont] = useState(0);
    const fonts = ['Comic Sans MS', 'Arial', 'Times New Roman', 'Courier New', 'Georgia', 'Verdana', 'Roboto Flex', 'Indie Flower', 'Dancing Script', 'Pacifico', 'Patrick Hand', 'Great Vibes', 'Caveat', 'Cursive', 'Bradley hand, cursive', 'Brush script mt, cursive', 'Lucida Console, monospace'];
    const canvasRef = useRef(null);
    const contextRef = useRef(null);
    const buttonsRef = useRef(null);
    const lineWidth = 5;

    useEffect(() => {
        const canvas = canvasRef.current;

        const setCanvasDimensions = () => {
            canvas.width = window.innerWidth;
            canvas.height = window.innerHeight;
        };

    // Set initial dimensions
    setCanvasDimensions();

    const context = canvas.getContext('2d');
    context.lineCap = 'round';
    context.strokeStyle = 'black';
    context.lineWidth = lineWidth;
    contextRef.current = context;

    const handleResize = () => {
        setCanvasDimensions();
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('load', setCanvasDimensions);

    // Add non-passive touch event listeners
    canvas.addEventListener('touchstart', startDrawing, { passive: false });
    canvas.addEventListener('touchend', finishDrawing, { passive: false });
    canvas.addEventListener('touchmove', draw, { passive: false });

    // Add UUIDs
    setWordId(nanoid());
    setCharId(nanoid());

    // Set font interval
    const fontInterval = setInterval(() => {
        // Avoid current font
        setCurrentFont((prevFont) => {
          // Get indexes excluding the current font
          const indexes = fonts.map((_, i) => i).filter(i => i !== prevFont);
          // Select a random index from the filtered indexes
          const newFont = indexes[Math.floor(Math.random() * indexes.length)];
          return newFont;
        });
      }, 750);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('load', setCanvasDimensions);

      // Remove non-passive touch event listeners
      canvas.removeEventListener('touchstart', startDrawing);
      canvas.removeEventListener('touchend', finishDrawing);
      canvas.removeEventListener('touchmove', draw);

      clearInterval(fontInterval);
    };
  }, []);

  const recordEvent = (x, y, z, eventType) => {
    const canvas = canvasRef.current;
    const eventRecord = {
        eventType,
        x,
        y,
        z,
        timestamp: Date.now(),
        height: canvas.height,
        width: canvas.width,
        sessionId: user['sessionId'],
        wordId: wordId,
        charId: charId,
        character: text[currentIndex],
    };
    console.log(eventRecord)

    setCurrentCharacterEvents(prevEvents => [
      ...prevEvents,
      eventRecord
    ]);
  };

  const getOffset = (nativeEvent) => {
    const rect = canvasRef.current.getBoundingClientRect();
    if (nativeEvent.touches) {
      const touch = nativeEvent.touches[0];
      return {
        x: touch.clientX - rect.left,
        y: touch.clientY - (rect.top/2), // iOS looks offsetted, TODO: confirm if this is the case with other phones
      };
    }

    return {
      x: nativeEvent.clientX - rect.left,
      y: nativeEvent.clientY - rect.top,
    };
  };

  const startDrawing = (event) => {
    const { x, y } = getOffset(event);
    contextRef.current.beginPath();
    contextRef.current.moveTo(x, y);
    contextRef.current.lineTo(x, y);
    contextRef.current.stroke();
    setIsDrawing(true);
    setShowNextButton(true);
    setShowCharacter(false);
  };

  const finishDrawing = (event) => {
    if (!isDrawing) return;
    contextRef.current.closePath();
    setIsDrawing(false);
    setHideButtons(false); // Show buttons when drawing ends
  };

  const draw = (event) => {
    if (!isDrawing) return; // Disabled to draw 'dots' for the letter `i`

    const { x, y } = getOffset(event);
    contextRef.current.lineTo(x, y);
    contextRef.current.stroke();

    // Check if drawing is near the buttons
    if (buttonsRef.current) {
      const buttonsRect = buttonsRef.current.getBoundingClientRect();
      if (
        x >= buttonsRect.left - 20 &&
        x <= buttonsRect.right + 20 &&
        y >= buttonsRect.top - 20 &&
        y <= buttonsRect.bottom + 20
      ) {
        setHideButtons(true); // Hide buttons when drawing is near
      }
    }
  };

    // Function to save the canvas as an image
    function saveCanvasAsImage() {
        const context = contextRef.current;

        // Determine the min and max x, y coordinates from the drawing events
        let minX = Math.floor(Math.min(...currentCharacterEvents.map(event => event.x)));
        let maxX = Math.ceil(Math.max(...currentCharacterEvents.map(event => event.x)));
        let minY = Math.floor(Math.min(...currentCharacterEvents.map(event => event.y)));
        let maxY = Math.ceil(Math.max(...currentCharacterEvents.map(event => event.y)));

        // Add some padding to the coordinates to 
        const padding = lineWidth;
        minX -= padding;
        maxX += padding;
        minY -= padding;
        maxY += padding;

        // Crop the canvas to the determined coordinates
        const croppedWidth = maxX - minX;
        const croppedHeight = maxY - minY;
        const croppedImageData = context.getImageData(minX, minY, croppedWidth, croppedHeight);

        // Create a temporary canvas to resize the cropped image
        const tempCanvas = document.createElement('canvas');
        const tempContext = tempCanvas.getContext('2d');
        tempCanvas.width = croppedWidth;
        tempCanvas.height = croppedHeight;
        tempContext.putImageData(croppedImageData, 0, 0);

        // Save the final image
        const finalImage = tempCanvas.toDataURL('image/png');
        const finalFullImage = canvasRef.current.toDataURL('image/png');
        const length = savedImages.length;


        if (length < text.length) {
            setSavedImages(prevImages => [...prevImages, finalImage]);
            setSavedFullImages(prevImages => [...prevImages, finalFullImage]);
        } else {
            // replace currentIndex with the new image
            const newImages = savedImages.slice();
            newImages[currentIndex] = finalImage;
            setSavedImages(newImages);
            const newFullImages = savedFullImages.slice();
            newFullImages[currentIndex] = finalFullImage;
            setSavedFullImages(newFullImages);
        }
    }

  const handleNextClick = () => {
    setTouchEvents(prevEvents => [...prevEvents, ...currentCharacterEvents]);
    setCurrentCharacterEvents([]);

    if (currentIndex < text.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setShowNextButton(false);
      setShowCharacter(true);
      setCharId(nanoid());
      saveCanvasAsImage();
      contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    } else if (currentRep < repetitions-1) {
      setCurrentRep(currentRep + 1); // Increment repetition count
      setCurrentIndex(0); // Start over with the first character
      setShowNextButton(false);
      setShowCharacter(true);
      setWordId(nanoid());
      setCharId(nanoid());
      saveCanvasAsImage();
      contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    } else {
      onDrawComplete([...touchEvents, ...currentCharacterEvents]);
    }
  };

  const handleClearClick = () => {
    contextRef.current.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    setCurrentCharacterEvents([]);
  };

  const getRandomCase = (char) => {
    return Math.random() < 0.5 ? char.toLowerCase() : char.toUpperCase();
};

  return (
    <div className="draw-page">
        <LogoutButton/>
        <div className="image-gallery">
            {savedImages.map((image, index) => (
            <img key={index} src={image} alt={`Saved drawing ${index + 1}`} className="saved-image"/>
            ))}
        </div>
        <canvas
            ref={canvasRef}
            className="drawing-canvas"
            onPointerDown={(e) => {
                startDrawing(e);
                recordEvent(e.clientX, e.clientY, e.pressure, e.pointerType === 'touch' ? 'touchstart' : 'mousedown');
            }}
            onPointerUp={(e) => {
                finishDrawing(e);
                recordEvent(e.clientX, e.clientY, e.pressure, e.pointerType === 'touch' ? 'touchend' : 'mouseup');
            }}
            onPointerMove={(e) => {
                draw(e);
                if (isDrawing) {
                recordEvent(e.clientX, e.clientY, e.pressure, e.pointerType === 'touch' ? 'touchmove' : 'mousemove');
                }
            }}
        />
        {showCharacter && (
            <div className='fullscreen'>
                <div className='text-explainer'>Please draw the character shown below</div>
                <div className="text-container"> 
                    {(savedImages.length > currentIndex) ? 
                    (<img key={currentIndex} src={savedFullImages[currentIndex]} alt={`Saved drawing ${currentIndex + 1}`}/>) 
                    : 
                    (<span 
                        className="char-span-large" 
                        style={{ fontFamily: fonts[currentFont] }}
                        >
                        {getRandomCase(text[currentIndex])}
                    </span>)}
                </div>
            </div>
        )}
        {showNextButton && (
            <div
            className={`button-container ${hideButtons ? 'hidden' : ''}`}
            id="draw-buttons"
            ref={buttonsRef}
            >
            <button className="clear-button" onClick={handleClearClick}>
                Clear
            </button>
            {currentCharacterEvents.length > 0 && (
                <button className="next-button" onClick={handleNextClick}>
                    Next
                </button>
            )}
            
            </div>
        )}
    </div>
  );
};

export default DrawPage;

