// src/contexts/UserContext.js

import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from "jwt-decode";


const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  const login = (token, callback) => {
    // Add session ID to user data
    localStorage.setItem('token', token);
    const userData = jwtDecode(token)
    console.log(userData)
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData));
    if (callback) {
        callback(userData);
    }
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem('user');
  };

  const updateUser = (newUserData) => {
    setUser(newUserData); // Update the context state
    localStorage.setItem('user', JSON.stringify(newUserData)); // Sync with localStorage
  };

  return (
    <UserContext.Provider value={{ user, login, logout, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
