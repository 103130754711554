// src/components/ThanksPage.js

import React from 'react';
import BigButton from './BigButton';
import LogoutButton from './LogoutButton';
import './ThanksPage.css';
import thanks from '../assets/thanks.png'; // Add the logo image in src/assets folder

const ThanksPage = ({ onHome }) => {

    return (
        <div className="thanks-page">
        <LogoutButton/>
        {(
            <div className="start-section-thanks show">
            <img src={thanks} alt="Tweekey Logo" id='thanks-img' />
            <p>Thanks for enrolling your <strong>Tweekey</strong>!</p>
            <p>This is your first step to better and safer login.</p>
            <BigButton onClick={onHome} text='Home' />
            </div>
        )}
        </div>
    );
};

export default ThanksPage;
